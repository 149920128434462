@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family:IRANSans;
  src:url(../public/static/fonts/IRANSansWeb_Light.woff)

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  direction: rtl;
  font-family: IRANSans;
}

.navItem{
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #883a3a; */
  background-color: rgba(255,255,255,.98);
  position: relative;
  flex-direction: column-reverse;
  /* font-size: 0.875rem; */
  flex: 1 1;
  padding: 4px 0;
  line-height: 2;
  border: none;
  cursor: pointer;
  outline: none;
  direction: ltr;
}
.navItem:hover {
  background-color: rgba(0,0,0,.04);
}

.active{
  background-color: rgba(0,0,0,.08)
}

.description {
  color: rgba(0,0,0,.56);
  font-size: .875rem;
  line-height: 24px;
  min-width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: pre;
  width: 0;
}

.slider-wrapper.axis-horizontal {
  direction: ltr;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: none !important;
}

.carousel .slide img {
 height: 45vh;
 border-radius: 10px;

}

.word-break {
  word-break: break-word;
}

.divider{
  background-color: rgba(0,0,0,.12);
  border: none;
  box-sizing: border-box;
  height: 1px;
  /* margin: 0; */
}

ul.thumbs.animated {
  direction: ltr;
  text-align: right;
}

ul.control-dots {
  direction: ltr;
}

.ltr{
  direction: ltr;
}

.chatitem:last-child{
margin-bottom: 35px;
}

.micon {
  text-rendering: auto;
  background-position: 50%;
  background-size: contain;
  display: inline-block;
  font-size: 1.25rem;
  font-style: normal;
  font-variant: normal;
  height: 20px;
  line-height: 1;
  vertical-align: middle;
  width: 20px;
}

.gridhandler{
grid-gap: 16px;
display: grid;
grid-template-columns: repeat(3,1fr);
margin-top: 5px;
}



.no-scrollbar::-webkit-scrollbar {
    display: none;

}

.no-scrollbar{
    -ms-overflow-style: none;
    scrollbar-width: none; 
}


.fadeInOut {
 -webkit-animation: fadeInOut 2s ease-in;
 animation: fadeInOut 2s ease-in;
}

@keyframes fadeInOut {
  0% {
      opacity: 0.5;
      transform: translateY(-32px);
    }

  50% {
      opacity: 0.75;
      transform: translateY(-16px);
    }
    
    100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@-webkit-keyframe fadeInOut {
  0% {
      opacity: 0.5;
      transform: translateY(32px);      
  }

  100 {
      opacity: 1;
      transform: translateY(32px);
  }
}

@media only screen and (max-width: 365px) {
    .navItem{
        font-size: 13px;
        font-weight: 400;
  }
  .imgInSmallDevices{
    flex: 0 0 110px;
    width: 110px;
  }
  .addad{
    font-size: 11px;
  }
}

@media only screen and (max-width: 230px) {
    .navItem{
        font-size: 10.5px;
  }
}


@media (min-width: 640px){
  .carousel .slide img {
 height: 65vh;
}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
